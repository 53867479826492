<script>
// Extensions
import View from "@/views/View";

// Mixins
import LoadSections from "@/mixins/load-sections";

export default {
  name: "ProHome",

  metaInfo() {
    return {
      title: this.$t("heroPro.title"),
      titleTemplate: (titleChunk) => {
        return titleChunk
          ? `${titleChunk} - ${this.$t("meta.titleTemplate")}`
          : this.$t("meta.titleTemplate");
      },
    };
  },

  extends: View,

  mixins: [
    LoadSections([
      "anonymous/sections/hero-pro",
      // 'sections/pricing-plan',
      "anonymous/sections/feature-pro-miniweb",
      "anonymous/sections/feature-pro-fill-agenda",
      "anonymous/sections/feature-pro-booking",
      "anonymous/sections/feature-pro-reminder",
      "anonymous/sections/pro-we-help-your-success",
      "sections/divider",
      "anonymous/sections/feature-pro-faq",
      "sections/divider",
      "anonymous/sections/pro-features",
      "sections/contact-us",
      "sections/info",
      // 'sections/social-media',
    ]),
  ],
};
</script>
